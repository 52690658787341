<template>
  <div class="trial-question">
    <div class="contents">
      <div class="left">
        <div class="category">{{ question.categoryName }}</div>
        <div class="attribute">{{ question.attribute }}</div>
        <div class="question">{{ question.text }}</div>
        <div class="description" v-for="(comment, index) in question.comments" :key="index">
          <div class="comment">{{ parsingComment(comment)[0] }}</div>
          <div class="comment context">{{ parsingComment(comment)[1] }}</div>
        </div>
      </div>
      <div class="right">
        <div class="choice" v-for="(choice, index) in question.choices" :key="index">
          <input type="radio" :id="choice.id" v-model="value" :value="choice"
                 @change="setAnswer(choice)" :class="isSelected(choice)"/>
          <label :for="choice.id" class="choice-text">
            <div>{{ choice.text }}</div>
            <div class="check"/>
          </label>
        </div>
      </div>
    </div>
    <div v-if="isShow" class="direction-buttons">
      <button v-if="question.index >= 1" class="secondary before" @click="$emit('beforeQuestion')">
        <span>{{ $t('back') }}</span>
      </button>
      <button v-if="question.index < totalCount-1"
              class="next" :class="{'inactive' : isInactive}" @click="$emit('nextQuestion')">
        <span>{{ $t('next') }}</span>
        <img class="next-image" src="~@/assets/images/ico_btn_next.svg" />
      </button>
      <button v-if="question.index === totalCount-1"
              class="next" :class="{'inactive' : isInactive}"
              @click.once="$emit('submitTrial')">
        <span>{{ $t('submit') }}</span>
      </button>
    </div>
    <div v-if="isShow" class="progress">
      <div class="bar">
        <div class="green-bar" :style="{width: `${percent}%`}"></div>
      </div>
      <div class="count">{{ question.index + 1 }}/{{ totalCount }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { isMobile } from '@/common/browser.util';

export default {
  name: 'TrialQuestion',
  props: {
    question: Object,
  },
  computed: {
    ...mapGetters(['trialQuestions', 'getAnswers', 'participantToken']),
    isMobile,
    totalCount() {
      return this.trialQuestions.length;
    },
    percent() {
      return (this.question.index + 1) / this.totalCount * 100;
    },
    isShow() {
      if (this.isFreePass) {
        return true;
      }
      if (window.screen.width < 700) {
        return false;
      }
      return !this.isMobile;
    },
    isFreePass() {
      return this.participantToken.startsWith('FREEPASS');
    },
  },
  beforeUpdate() {
    if (this.getAnswers[this.question.id] === undefined) {
      this.isInactive = true;
    } else {
      this.isInactive = false;
    }
    this.answeredCount = Object.keys(this.getAnswers).length;
  },
  data() {
    return {
      value: '',
      answeredCount: 0,
      isInactive: true,
    };
  },
  watch: {
    getTotalAnswers() {
      return this.getAnswers;
    },
  },
  methods: {
    ...mapMutations(['setAnswers']),
    isSelected(choice) {
      let retVal = '';
      if (this.getAnswers[this.question.id] !== undefined
        && this.getAnswers[this.question.id].id === choice.id) {
        retVal = 'selected';
      }
      return retVal;
    },
    setAnswer(value) {
      value.categoryId = this.question.categoryId;
      value.categoryName = this.question.categoryName;
      value.questionId = this.question.id;
      value.order = this.question.index;
      const id = this.question.id;
      this.setAnswers({ id, value });
      this.isInactive = false;
      this.$emit('isSetAnswer', this.isInactive);
    },
    parsingComment(text) {
      return text.split(' : ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-question {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 48px 27px 60px 27px;
  width: 100%;
  min-height: 808px;
  border-radius: 8px;
  border: 0.5px solid #ededed;

  .contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .left {
      flex: 1;
      min-width: 346px;
      margin-right: 22px;

      .category {
        font-size: $font_size_24;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: normal;
        color: $primary_color;
      }

      .attribute {
        font-size: $font_size_16;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: normal;
        color: rgba(0, 202, 148, 0.5);
        margin-bottom: 12px;
      }

      .question {
        font-size: $font_size_27;
        font-weight: 600;
        line-height: 1.48;
        letter-spacing: normal;
        word-break: keep-all;
        color: #2a324b;
        margin-bottom: 32px;
      }

      .description {
        font-size: $font_size_16;
        font-weight: $font_weight_400;
        line-height: 1.38;
        letter-spacing: normal;
        word-break: keep-all;
        color: #2a324b;

        .comment {
          &.context {
            margin-left: 8px;
          }
        }
      }

      .description + .description {
        margin-top: 6px;
      }
    }

    .right {
      flex: 1;
      min-width: 346px;
      margin-top: 33px;
      padding-left: 18px;
      margin-bottom: 13px;

      .choice {
        margin-bottom: 18px;
        font-size: $font_size_18;
        line-height: 1.22;
        letter-spacing: normal;
        color: #4a4a4a;

        &:last-child {
          margin-bottom: 0;
        }

        input[type=radio] {
          & + label {
            background-color: #f7f8fa;
            min-height: 89px;
            width: 100%;
            border-radius: 4px;
            padding: 22px 68px 22px 22px;
            word-break: keep-all;
            cursor: pointer;
            position: relative;

            .check {
              position: absolute;
              top: calc(50% - 0.5em);
              right: 22px;
              height: 20px;
              width: 24px;
              background-size: 24px 20px;
            }
          }

          &:hover + label {
            .check {
              background-image: url("~@/assets/images/ico_selected_hover.svg");
            }
          }

          &.selected + label {
            background-color: #F2FDFA;
            color: #00ca94;

            .check {
              background-image: url("~@/assets/images/ico_selected_active.svg");
            }
          }
        }

        .choice-text {
          min-height: 45px !important;
        }

        .choice-text:after {
          content: '';
          min-height: inherit;
          font-size: 0;
        }
      }
    }
  }

  .direction-buttons {
    display: flex;
    flex-direction: row;
    align-self: flex-end;

    .before {
      border: solid 1px #bcbfc6;

      &:hover {
        border: solid 1px #00ca94;
      }

      &:active {
        background-color: rgba(0, 202, 148, 0.1);
        border: solid 1px #00ca94;
      }
    }

    .next {
      margin-left: 18px;
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      margin-bottom: 30px;

      &.inactive {
        background-color: rgba(42, 50, 75, 0.2);
        pointer-events: none;
      }

      .next-image {
        transform: rotate(90deg);
        margin-left: 8px;
      }
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    // background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 202, 148, 0.03);

    .bar {
      width: 360px;
      height: 8px;
      background: #ffffff;
      box-shadow: 0 0 8px 0 rgba(0, 202, 148, 0.15);
      border-radius: 10px;

      .green-bar {
        width: 30%;
        height: 100%;
        background: #00ca94;
        border-radius: 10px;
      }
    }

    .count {
      margin-left: 12px;
      font-size: $font_size_12;
      font-weight: $font_weight_400;
      line-height: 1.42;
      letter-spacing: normal;
      color: #4a4a4a;
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-question {
    flex-shrink: 0;
    padding: 24px 36px 105px;
    border-radius: 0;
    border: none;
    box-shadow: none;

    .contents {
      height: 100%;
      flex-wrap: wrap;
      flex-shrink: 0;

      .right {
        padding-left: 0;
      }

      .left {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: $max_width_size_for_mobile) and (max-width: $max_width_size_for_tablet) and (orientation: landscape) {
  .trial-question {
    max-width: 1330px;
    width: 100%;
  }
}
</style>
