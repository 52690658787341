<template>
  <div class="copyright">
    <div class="text-container">
      <div>Copyright © 2021, Sally</div>
      <div class="img"></div>
      <div class="reserved">All Rights Reserved.</div>
    </div>
    <div class="url-container">
      <a :href="privacyURL" target="_blank" class="bold">{{ $t('privacy_policy') }}</a>
      <span> | </span>
      <a :href="termURL" target="_blank">{{ $t('terms_use') }}</a>
      <span> | </span>
      <a :href="contactURL" :target="blank">{{ $t('contact') }} </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SURVEY_ID_SRM } from '@/common/config';

export default {
  name: 'Copyright',
  data() {
    return {
      privacyURL: '/privacyandpolicy',
      termURL: '/termsanduse',
      contactURL: 'mailto:admin@sally.coach',
      blank: '_self',
    };
  },
  props: {
    surveyId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['trialSurvey']),
  },

  watch: {
    surveyId(newSurveyId) {
      this.setUrl(newSurveyId);
    },
    trialSurvey(newTrial) {
      this.setUrl(newTrial.id);
    }
  },
  methods: {
    setUrl(surveyId) {
      if (surveyId === SURVEY_ID_SRM) {
        this.privacyURL = 'https://www.samsungsds.com/en/etc/contact/agree/foot_event.html';
        this.termURL = 'https://www.samsungsds.com/en/etc/contact/agree/legal.html';
        this.contactURL = 'https://www.samsungsds.com/us/contact/about_contact.html';
        this.blank = '_blank';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.copyright {
  font-size: $font_size_12;
  font-weight: $font_weight_400;
  letter-spacing: normal;
  color: #acacac;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;

  .text-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .img {
      background-image: url("~@/assets/images/ico_beta_grey.svg");
      width: 29px;
      height: 13px;
      margin: 0 3px;
    }

    .reserved {
      margin-left: 5px;
      margin-right: 12px;
    }
  }

  .url-container {
    a {
      color: #acacac;
      margin-left: 3px;

      &.bold {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .copyright {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 15px;
  }
}
</style>
