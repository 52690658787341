<template>
  <div class="trial-survey">
    <!-- 컴포넌트 위치가 모바일과 pc버전일때 달라서 이에 대한 해결책 필요 -->
    <div v-if="isSurveySRM" class="trial-survey--language">
      <select-language/>
    </div>
    <div v-if="isPhoneMode" class="mobile-header">
      <div class="progress">
        <div class="bar">
          <div class="green-bar" :style="{width: `${percent}%`}"></div>
        </div>
      </div>
      <div class="bread">
        <span class="green">{{ trialSurvey.name }}</span>
      </div>
    </div>
    <div class="scroll" id="survey-scroll">
      <div class="wrapper">
        <div class="inner">
          <div v-if="!isPhoneMode" class="bread">
            <span class="text"> {{ textHeader }}</span>
            <span>/</span>
            <span class="green">{{ trialSurvey.name }}</span>
          </div>
          <trial-question :question="currentQuestion"
                          @isSetAnswer="isSetAnswer"
                          @submitTrial="submitTrial"
                          @beforeQuestion="beforeQuestion"
                          @nextQuestion="nextQuestion"/>
          <div v-if="!isPhoneMode" class="logo">
            <span class="text">Powered by</span>
            <img class="image" src="~@/assets/images/sally-logo-full.svg"/>
          </div>
        </div>
        <copyright/>
      </div>
    </div>
    <div v-if="isPhoneMode" class="direction-buttons">
      <button class="secondary before"
              @click="beforeQuestion">
        <span>{{ $t('back') }}</span>
      </button>
      <button v-if="currentQuestion.index < totalCount-1"
              class="next" :class="{'inactive' : isInactive}"
              @click="nextQuestion">
        <span>{{ $t('next') }}</span>
        <img v-if="!isPhoneMode" class="next-image" src="~@/assets/images/ico_btn_next.svg"/>
      </button>
      <button v-if="currentQuestion.index === totalCount-1"
              class="next" :class="{'inactive' : isInactive}"
              @click.once="submitTrial">
        <span>{{ $t('submit') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Copyright from '@/components/Common/Copyright.vue';
import TrialQuestion from '@/components/Trial/TrialQuestion.vue';
import { isMobile } from '@/common/browser.util';
import { SURVEY_ID_SRM } from '@/common/config';
import SelectLanguage from '@/components/Common/SelectLanguage.vue';

export default {
  name: 'TrialSurvey',
  components: {
    SelectLanguage,
    Copyright,
    TrialQuestion,
  },
  async created() {
    this.trialSurvey = await this.getTrialSurvey(this.$route.params.id);
    await this.fetchTrialQuestions(this.trialSurvey.questionnaireId);
  },
  computed: {
    ...mapGetters(['trialQuestions', 'getAnswers', 'participantToken', 'entryPoint']),
    isSurveySRM() {
      return this.trialSurvey.id === SURVEY_ID_SRM;
    },
    textHeader() {
      return this.trialSurvey.id === SURVEY_ID_SRM ? 'SRM Competency Assessment' : 'Digital Transformation Assessment';
    },
    isMobile,
    isPhoneMode() {
      if (window.screen.width < 700) {
        return true;
      }
      return this.isMobile && !this.isFreePass;
    },
    totalCount() {
      return this.trialQuestions.length;
    },
    percent() {
      return (this.currentQuestion.index + 1) / this.totalCount * 100;
    },
    currentQuestion() {
      if (this.trialQuestions.length > 0) {
        return this.trialQuestions[this.currentQuestionIndex];
      }
      return {};
    },
    isFreePass() {
      return this.participantToken.startsWith('FREEPASS');
    },
  },
  beforeUpdate() {
    if (this.getAnswers[this.currentQuestion.id] === undefined) {
      this.isInactive = true;
    } else {
      this.isInactive = false;
    }
  },
  data() {
    return {
      currentQuestionIndex: 0,
      trialSurvey: {},
      isInactive: true,
    };
  },
  methods: {
    ...mapActions(['getTrialSurvey', 'fetchTrialQuestions', 'submitAnswers', 'submitAnswersForFp']),
    beforeQuestion() {
      this.currentQuestionIndex -= 1;
    },
    nextQuestion() {
      this.currentQuestionIndex += 1;
      this.moveToTop();
    },
    isSetAnswer(isSet) {
      this.isInactive = isSet;
    },
    async submitTrial() {
      const id = this.$route.params.id;
      const param = this.getAnswers;

      const resultUrlId = await this.submitAnswers({
        id,
        param,
        participantToken: this.participantToken,
      });
      this.$router.push(`/result/${resultUrlId}`);
    },
    moveToTop() {
      const element = document.getElementById('survey-scroll');
      if (element) {
        element.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-survey {
  width: 100%;
  height: 100%;
  padding: 24px 20px;

  &--language {
    position: relative;
    z-index: 9;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 20px;
    display: flex;
    justify-content: flex-end;
  }

  .scroll {
    width: 100%;
    height: 100%;
    overflow: auto;

    .wrapper {
      max-width: $max_width;
      margin: auto;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .inner {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-items: center;

        .bread {
          display: flex;
          flex-direction: row;
          align-self: flex-start;
          margin-bottom: 24px;
          font-size: $font_size_14;
          font-weight: normal;
          line-height: 1.36;
          letter-spacing: -0.35px;
          color: #4a4a4a;

          .text {
            margin-right: 5px;
          }

          .green {
            margin-left: 5px;
            color: $primary_color;
          }
        }

        .logo {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 6px;

          .text {
            font-size: $font_size_12;
            font-weight: 500;
            line-height: 1.42;
            letter-spacing: normal;
            color: #2a324b;
          }

          .image {
            width: 37px;
            margin-left: 5px;
          }
        }
      }

      .copyright {
        flex-shrink: 0;
        padding-top: $standard_padding_size;
      }
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-survey {
    height: 100%;
    width: 100%;
    position: relative;

    .mobile-header {
      position: absolute;
      width: 100%;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      z-index: 999;

      .progress {
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 202, 148, 0.03);

        .bar {
          width: 100%;
          height: 5px;
          background: #ffffff;

          .green-bar {
            width: 30%;
            height: 100%;
            background: #00ca94;
          }
        }
      }

      .bread {
        margin-top: 13px;
        margin-bottom: 18px;
        font-size: $font_size_14;
        color: #4a4a4a;

        .green {
          align-self: center;
          padding-left: 18px;
          color: $primary_color;
        }
      }
    }

    .scroll {
      top: 54px;
      overflow: auto;
      width: 100%;
      height: calc(100% - 102px);

      .wrapper {
        padding: 0px;
        position: initial;
        margin-bottom: 0;

        .inner {
          height: 100%;
          margin-bottom: 54px;
        }
      }
    }

    .direction-buttons {
      position: absolute;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      width: 100%;
      box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.16);

      .before {
        width: 100%;
        height: 48px;
        border: none;

        span {
          font-size: 18px;
          font-weight: 600;
          color: rgba(42, 50, 75, 0.5);
        }

        &:hover {
          border: none;
        }

        &:active {
          background-color: rgba(0, 202, 148, 0.1);
          border: solid 1px #00ca94;
        }
      }

      .next {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-self: flex-end;

        span {
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
        }

        &.inactive {
          background-color: rgba(42, 50, 75, 0.2);
          pointer-events: none;
        }

        .next-image {
          transform: rotate(90deg);
          margin-left: 8px;
        }
      }
    }

    .copyright {
      display: none;
    }
  }
}

@media only screen and (min-width: $max_width_size_for_mobile) and (max-width: $max_width_size_for_tablet) and (orientation: landscape) {
  .trial-survey {
    height: 100%;
    width: 100%;
    position: relative;

    &--language {
      max-width: 1280px;
    }

    .scroll {
      overflow: auto;
      width: 100%;
      height: 100%;

      .wrapper {
        max-width: 1280px;
        width: 100%;
        max-height: 600px;
        padding: 0;
        position: initial;
        margin-bottom: 0;

        .inner {
          height: 100%;
          margin-bottom: 54px;

          .bread {
            display: none;
          }

          .logo {
            display: none;
          }
        }
      }
    }

    .copyright {
      display: none;
    }
  }
}
</style>
